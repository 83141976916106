import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON, patchJSON, postJSON } from 'api/fetch';

import { getPayrollFeatureEnabled } from 'selectors/payroll';

import {
  SHIFT_PAY_PROMOTIONS_DISMISS_PAY_ANY_DAY_BANNER_PATH,
  SHIFT_PAY_PROMOTIONS_SEND_PAY_ANY_DAY_FEEDBACK_PATH,
} from 'features/shiftPay/constants';

import { transformKeysToCamelcase } from 'util/transformKeysToCamelcase';

export const fetchDashboardData = createAsyncThunk(
  'payroll/fetchPayrollRuns',
  () => fetchJSON(`/payroll.json`)
);

export const createQualifiedLeadEvent = createAsyncThunk(
  'payroll/createQualifiedLeadEvent',
  ({ eventName }) => postJSON('/payroll/qualified_lead_events', { eventName })
);

export const fetchPayrollIneligibleEmployees = createAsyncThunk(
  'payroll/fetchPayrollIneligibleEmployees',
  (_, { getState, rejectWithValue }) => {
    const state = getState();
    const payrollIsActive = getPayrollFeatureEnabled(state, 'payroll_active');
    if (payrollIsActive) {
      return fetchJSON(`/payroll/payroll_ineligible_employees.json`);
    }

    return rejectWithValue();
  }
);

export const fetchComponents = createAsyncThunk(
  'fetchComponents',
  (_, { rejectWithValue }) =>
    fetchJSON(`/payroll/implementation/dashboard_components.json`).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

export const getMailTaxFormsData = createAsyncThunk(
  'getMailTaxFormsData',
  (_, { rejectWithValue }) =>
    fetchJSON(`/payroll/get_mail_tax_forms_data`).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

export const updateMailTaxForms = createAsyncThunk(
  'updateMailTaxForms',
  (payload, { rejectWithValue }) =>
    patchJSON('/payroll/update_mail_tax_forms', payload).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

export const dismissPayAnyDayPromoBanner = () =>
  postJSON(SHIFT_PAY_PROMOTIONS_DISMISS_PAY_ANY_DAY_BANNER_PATH, {});
export const sendPayAnyDayPromoFeedback = (reason, reasonElaboration) =>
  postJSON(SHIFT_PAY_PROMOTIONS_SEND_PAY_ANY_DAY_FEEDBACK_PATH, {
    reason,
    reason_elaboration: reasonElaboration,
  });

const payrollDashboardSlice = createSlice({
  name: 'payrollDashboard',
  initialState: {
    isFetching: false,
    payPeriodDateRange: '',
    payPeriodStartDate: '',
    payPeriodEndDate: '',
    payDate: '',
    rawPayDate: '',
    payrollDue: '',
    payrollDueColor: '',
    payrollDuePillVariant: '',
    activeLocationNames: '',
    runLocationNamesToSentence: '',
    isPayrollPastDue: false,
    isPayrollDueToday: false,
    submissionDeadlineDate: '',
    submissionDeadlineTime: '',
    submissionDeadlineCountdown: '',
    autopayrollSubmissionDeadlineTime: '',
    autopayrollSubmissionDeadlineCountdown: '',
    showPayrollHistory: false,
    currentPayPeriodPayrollRun: null,
    payrollHistoryInitialDates: '',
    payrollHistoryCalendarPickerDateRangeOptions: '',
    isRunStandardPayrollButtonDisabled: false,
    isRunOffCyclePayrollButtonDisabled: false,
    isBankAccountEmpty: false,
    needsReviewEmployees: [],
    isManuallyPaid: null,
    hasEligibleEmployees: false,
    isPayrollCompanyChurned: false,
    featureFlags: {
      offCyclePayrollButton: false,
      standardPayrollRunWidget: false,
      needsReviewWidget: false,
      payrollReports: false,
      manageTimeOff: false,
    },
    implementationComponents: [],
    implementationMetadata: {},
    hasFailedPreNoteValidation: false,
    showAdjustedCheckPaydaysAlert: false,
    showPayAnyDayPromoBanner: false,
    showPayAnyDayPromoFeedbackModal: false,
    showPromoteTwoStepVerificationModal: false,
    showTaxFormsWidget: false,
    submissionDeadlineAdjustedBanner: null,
    recentFailedPaymentsPayrollRun: {},
    showFailedPaymentsRefundedModal: null,
    taxDocumentsCount: null,
    mailTaxFormsData: {},
    mailTaxFormsDataPending: false,
    companyName: '',
    referralModal: { open: false, referrer: '' },
    isReferralSent: false,
  },
  reducers: {
    setReferralModal: (state, action) => {
      state.referralModal = action.payload;
    },
    setIsReferralSent: (state, action) => {
      state.isReferralSent = action.payload;
    },
    setIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setShowPayAnyDayPromoBanner: (state, action) => {
      state.showPayAnyDayPromoBanner = action.payload;
    },
    setShowPayAnyDayPromoFeedbackModal: (state, action) => {
      state.showPayAnyDayPromoFeedbackModal = action.payload;
    },
    resetShowPromoteTwoStepVerificationModal: state => {
      state.showPromoteTwoStepVerificationModal = false;
    },
    updateRecentFailedPaymentsPayrollRun: (state, action) => {
      state.recentFailedPaymentsPayrollRun = action.payload;
    },
  },
  extraReducers: {
    [fetchDashboardData.pending]: state => {
      state.isFetching = true;
    },
    [fetchDashboardData.fulfilled]: (state, action) => {
      state.payPeriodDateRange = action.payload.pay_period_date_range;
      state.payPeriodStartDate = action.payload.pay_period_start_date;
      state.payPeriodEndDate = action.payload.pay_period_end_date;
      state.payDate = action.payload.pay_date;
      state.rawPayDate = action.payload.raw_pay_date;
      state.payrollDue = action.payload.payroll_due;
      state.payrollDueColor = action.payload.payroll_due_color;
      state.payrollDuePillVariant = action.payload.payroll_due_pill_variant;
      state.activeLocationNames = action.payload.active_location_names;
      state.runLocationNamesToSentence =
        action.payload.run_location_names_to_sentence;
      state.isPayrollPastDue = action.payload.is_payroll_past_due;
      state.isPayrollDueToday = action.payload.is_payroll_due_today;
      state.isAutopayrollPastSubmission =
        action.payload.autopayroll_past_submission;
      state.submissionDeadlineDate = action.payload.submission_deadline_date;
      state.submissionDeadlineDateWithYear =
        action.payload.submission_deadline_date_with_year;
      state.submissionDeadlineTime = action.payload.submission_deadline_time;
      state.submissionDeadlineCountdown =
        action.payload.submission_deadline_countdown;
      state.autopayrollSubmissionDeadlineTime =
        action.payload.autopayroll_submission_deadline_time;
      state.autopayrollSubmissionDeadlineCountdown =
        action.payload.autopayroll_submission_deadline_countdown;
      state.showPayrollHistory = action.payload.show_payroll_history;
      state.isPayrollCompanyChurned = action.payload.is_payroll_company_churned;
      state.featureFlags = transformKeysToCamelcase(
        action.payload.feature_flags
      );
      state.isRunStandardPayrollButtonDisabled =
        action.payload.block_run_standard_payroll;
      state.isRunOffCyclePayrollButtonDisabled =
        action.payload.block_run_off_cycle_payroll;
      state.isBankAccountEmpty = action.payload.is_bank_account_empty;
      state.companyName = action.payload.company_name;
      state.isCheckBankAccountNotCreatedOrFailed =
        action.payload.is_check_bank_account_not_created_or_failed;
      state.currentPayPeriodPayrollRun =
        action.payload.current_pay_period_payroll_run;
      state.payrollHistoryInitialDates =
        action.payload.payroll_history_initial_dates;
      state.payrollHistoryCalendarPickerDateRangeOptions =
        action.payload.payroll_history_calendar_picker_date_range_options;
      state.needsReviewEmployees = action.payload.needs_review_employees;
      state.isManuallyPaid = action.payload.is_manually_paid;
      state.hasEligibleEmployees = action.payload.has_eligible_employees;
      state.showAdjustedCheckPaydaysAlert =
        action.payload.show_adjusted_check_paydays_alert;
      state.showPayAnyDayPromoBanner =
        action.payload.show_pay_any_day_promo_banner;
      state.showPromoteTwoStepVerificationModal =
        action.payload.show_promote_two_step_verification_modal;
      state.showTaxFormsWidget = action.payload.show_tax_forms_widget;
      state.taxDocumentsCount = action.payload.tax_documents_count;
      state.submissionDeadlineAdjustedBanner =
        action.payload.submission_deadline_adjusted_banner;
      state.recentFailedPaymentsPayrollRun =
        action.payload.recent_failed_payments_payroll_run;
      state.showFailedPaymentsRefundedModal =
        action.payload.show_failed_payments_refunded_modal;
      state.isFetching = false;
    },
    [fetchPayrollIneligibleEmployees.fulfilled]: (state, action) => {
      state.needsReviewEmployees = action.payload;
    },
    [fetchPayrollIneligibleEmployees.rejected]: state => {
      state.needsReviewEmployees = [];
    },
    [fetchComponents.pending]: state => {
      state.fetchDashboardComponentsPending = true;
    },
    [fetchComponents.fulfilled]: (state, action) => {
      state.implementationComponents = action.payload.components;
      state.implementationMetadata = transformKeysToCamelcase(
        action.payload.metadata
      );
      state.fetchDashboardComponentsPending = false;
    },
    [fetchComponents.rejected]: state => {
      state.implementationComponents = [];
      state.implementationMetadata = {};
      state.fetchDashboardComponentsPending = false;
    },
    [getMailTaxFormsData.pending]: state => {
      state.mailTaxFormsDataPending = true;
    },
    [getMailTaxFormsData.fulfilled]: (state, action) => {
      state.mailTaxFormsDataPending = false;
      state.mailTaxFormsData = action.payload;
    },
    [getMailTaxFormsData.rejected]: state => {
      state.mailTaxFormsDataPending = false;
    },
  },
});

export const payrollDashboardReducer = payrollDashboardSlice.reducer;
export const payrollDashboardActions = payrollDashboardSlice.actions;
